exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-cibus-2-js": () => import("./../../../src/pages/cibus2.js" /* webpackChunkName: "component---src-pages-cibus-2-js" */),
  "component---src-pages-cibus-js": () => import("./../../../src/pages/cibus.js" /* webpackChunkName: "component---src-pages-cibus-js" */),
  "component---src-pages-elquebuscagana-js": () => import("./../../../src/pages/elquebuscagana.js" /* webpackChunkName: "component---src-pages-elquebuscagana-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-japon-js": () => import("./../../../src/pages/japon.js" /* webpackChunkName: "component---src-pages-japon-js" */),
  "component---src-pages-mcd-js": () => import("./../../../src/pages/mcd.js" /* webpackChunkName: "component---src-pages-mcd-js" */),
  "component---src-pages-verify-code-js": () => import("./../../../src/pages/verify-code.js" /* webpackChunkName: "component---src-pages-verify-code-js" */)
}

